* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,

.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}


.services {
  background-image: url('./components/pages/image/green1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
 
}

.products {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.serviceAbout {
  background-image: url('./components/pages/image/bubbles.jpeg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}
.Pic{
  width: 30%;
  margin-left: 59%;
  margin-top: 10%;
}
.h1-color{
  color:red;
  font-family: 'Font Awesome 5 Brands';
}
.sign-up {
  background-image: url('./components/pages/image/light.jpg') ;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
 
  
}
.login{
  
    background-image: url('/images/green1.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
   
  
}
.slider {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 1000px;
  height: 600px;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
  
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);

}

table, th, td {
    border: solid 1px #DDD;
    border-collapse: collapse;
   
   
    width: 700px;
}
.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
  
  

.container{
  width: 1170px;
 
  font-size: 50%;
  
  display: flex;
  margin-top: -30%;
  justify-content: center;
}
.employeeform{
  margin-top: 3%;
}
.document-width{
width:12px;  
     }

.ant-form-item-control-input-content{
  display :flex;
  justify-content:space-evenly;   }  
.ant-form-item-control-input-content{
display :flex;
justify-content:space-evenly;  
  width: 350px;
  margin-top: -3%;}
.ant-select-arrow .anticon {
  margin-left: 2%; 


}
.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}
.zoom {
  
  margin-top: 20%;
   transition: transform .2s; 
   width: 20%;
   height: 15%;
   margin: 0 auto;
 }
 
   
 .zoom:hover {
   transform: scale(1.5); 
 }
 @media(max-width:420px){

  .image{width: 100%;
   
    border-radius: 10px;}
  .zoom {
  
 
    transition: transform .2s; 
    width: 344px;
    height: 60%;
    margin: 0 auto;
  }
  
    
  .zoom:hover {
    transform: scale(1.5); 
  }
  
  .services {
    background-image: url('./components/pages/image/green1.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
   height: 100%;
  }
  .about{
 
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: rgb(255, 6, 6);
    height: 100%;
  
  }
  .sign-up{
    display: flex;
    flex-direction: column;
    background-image: url('./components/pages/image/light.jpg') ;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
  }
  .Pic{
    width: 44%;
margin-left: 29%;
  }
  
  
 }
 
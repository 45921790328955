.form {
    z-index: 5;
    text-align: center;
    padding: 50px;

}
.form_head {
    
    color: white;
    margin-bottom: -1em;
    font-size: xx-large;
}
.form span {
    
    font-size: large;
    font-weight: bold;
 }
 .form Input {
     opacity: 0.7;    
     border-radius: 5px;
 }
 .form Button {
     width: 100%;
     border-radius: 50px;
 }
 .textarea {
     opacity: 0.7;   
 }
.ant-input{
    width: 100%;
    padding :10px;
}
.ant-btn{
    margin-top: 9%;
    background-color: royalblue;
}
 .ant-form-item-explain{
     font-size: 40%;
     color:red;
 }
 .ant-form-item-required{
     color:red;
 }
 .ant-form-item-no-colon{
    color:black;
 }

  .Hero-p{
    color: white;
    margin-top: -46%;
    /* color: white; */
    font-size: 269%;
    font-family: cursive;
    height: 59%;
    width: 53%;
    background: white;
    padding: 2%;
    margin-left: 38%;
  
  
  }
  @media(max-width:420px){
    .Hero-p{ color: white;
margin-top: -66%;
color: white;
font-size: 127%;
font-family: cursive;
height: 59%;
width: 53%;
background: white;
padding: 2%;
margin-left: 2%;}}
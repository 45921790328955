.center {
    text-align: center;
}

.right {
    text-align: right;
}

.bold {
    font-weight: bold;
}

.mainTable {
    font-size: 12px;
    margin: 8px;
}


@page {
    size: A4;
    margin: 2px;
    font-size: 12pt;
}


td {
    border: 1px solid gray;
    padding: 2px 4px;
}

.noLeftBorder, .noSideBorder {
    border-left: 0;
}

.noRightBorder, .noSideBorder {
    border-right: 0;
}

.noTopBorder, .noVerticalBorder {
    border-top: 0;
}

.noBottomBorder, .noVerticalBorder {
    border-bottom: 0;
}

.noBorder {
    border: 0;
}

.heading {
    font-weight: bold;
    font-size: large;
}

.h3 {
    font-weight: bold;
    font-size: large;
}

.h2 {
    font-weight: bold;
    font-size: large;
}

.signatureGap {
    height: 100px;
    vertical-align: top;
}

.certified {
    font-size: small;
}

.innerRow td {
    border-top: 0;
    border-bottom: 0;
    padding: 8px 4px;
}

.firstRow td {
    border-bottom: 0;
    padding: 8px 4px;
}

.lastRow td {
    border-top: 0;
    padding: 8px 4px;
}

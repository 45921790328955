.training{
    margin-top:1%;
    font-size:100%;
    color:mediumblue;
    font-family:-moz-initial;
}
.table{
    margin-top: 5%;
    border: 3px solid rgb(221, 221, 221);
    width: 700px;
    margin-left: 5%;
}
.service_h1{
    color: yellow;
    font-size: 80%;
    font-family: serif;
    text-align: center;
}
.service_p{
    font-size: 50%;
    font-family: -moz-initial;
    padding: 30px;
}
.service_button{
    background-color: gold;
    padding: 2px;
    font-size: 50%;
    margin-left: 40%
}
.service_div{
    margin-top: 5%;
    width: 100%;
    background-color: black;
   
}
.service_list{
    color: #ba47ac;
    display: flex;
    justify-content: center;
    font-family: emoji;
    font-size: 100%;
}
.list_service{
    color:white;
    margin-left:5%;
    font-size:50%;
   
    box-sizing: border-box;
  
    padding: 20px;
    /* font-family: 'PT Sans', sans-serif; */
    font-family: cursive;

}
.service_img{
    margin-top: -24%;
    width: 19%;
    margin-left: 70%;
}
.training_div{
    background-color: #000000;
    margin-top: 5%;
    width: 100%;

}
.work{
    
        width: 36%;
        margin-left: 27%;
        margin-top: 5%;
    
}
.crp{
    float: left;
    margin: 0;
    color: #8d8d8c;
    font-size: 15px;
}
@media(max-width:420px){
.training {
    margin-top: 0%;
    font-size: 81%;
    color: mediumblue;
    font-family: -moz-initial;
}
.table {
    margin-top: 5%;
    border: 3px solid rgb(221, 221, 221);
    width: 662px;
    margin-left: 18%;
    width: 64%;
}
.service_button {
    background-color: gold;
    padding: 0px;
    font-size: 41%;
    margin-left: 35%;
}
.list_service {
    color: white;
    margin-left: 19%;
    font-size: 42%;}
.service_list{
    color: #ba47ac;
    display: flex;
    justify-content: center;
    font-family: emoji;
    font-size: 100%;
}
.service_div {
    margin-top: 5%;
    width: 100%;
    background-color: black;
margin-left: 0%;}
.service_img {
    margin-top: 5%;
    width: 56%;
    margin-left: 22%;
}
.work {
    width: 68%;
    margin-left: 16%;
    margin-top: 5%;
}
}
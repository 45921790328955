.About-h1{
  color: #ba47ac;
    display: flex;
    justify-content: center;
    font-family: emoji;
}
.About-p{
  margin-top: -47%;
  /* color: white; */
  font-size: 127%;
  font-family: system-ui;
  height: 59%;
  width: 34%;
  background: white;
  padding: 2%;
  margin-left: 10%;


}

.smile{
  width: 5%;
}

@media(max-width:420px){
 .list{
     color:white;
     margin-left:8%;
     font-size:50%;
 }
 .Image{
     margin-top: 10%;
width: 50%;
margin-left: 29%;
 }
 .About-p{
  margin-top: 1%;
  
  font-size: 109%;
  font-family: system-ui;
  height: 56%;
  width: 83%;
  background: white;
  padding: 2%;
  margin-left: 10%;
 }

}

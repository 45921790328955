.address{
    padding: 4rem 0 2rem 0;
        display:flex;
        flex-direction: column;
        justify-content: center;
}
.address_h1{
    display: flex;
    justify-content: center;
    color: rgb(255, 0, 85);
    font-size: 80%;
    font-family: 'Font Awesome 5 Brands';

}
.address-p{
    margin-left: 15%;
    font-size: 55%;
    font-family: cursive;

}
.address-h6{
    display: flex;
    justify-content: center;
    font-family: inherit;
}
.whatsapp{
    background-image: url(/static/media/whatsapp.55d07228.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 422px;
    height: 138px;
    margin-left: 31%;
    margin-top: 5%;
}
.comp{
    display: flex;
    justify-content: center;
    margin-top:5%;
    color:darkgreen;
    font-family: 'Font Awesome 5 Brands';
    font-size: 93%;
}
@media(max-width:420px){
    .address_h1{
    display: flex;
justify-content: center;
color: red;
font-size: 80%;
}
.comp{
    display: flex;
justify-content: center;
margin-top: 5%;
color: darkgreen;
font-size: 80%;
}
.address-p{
    margin-left: 4%;
    font-size: 50%
}
.whatsapp{
background-image: url(/static/media/whatsapp.55d07228.jpg);
background-position: center;
background-size: cover;
background-repeat: no-repeat;
width: 276px;
height: 122px;
margin-left: 12%
}
}